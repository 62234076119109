/** @format */

@import './variables.scss';

.button {
  cursor: pointer;
  background-color: rgb(0, 105, 237);
  color: white;
  border: none;
  border-radius: $border-radius;
  padding-left: 12px;
  padding-right: 12px;

  &:hover {
    background-color: #005cde;
  }

  &:disabled {
    background-color: rgba(0, 105, 237, 0.4);
    cursor: not-allowed;
  }
}
