/** @format */

@import '../variables.scss';

$horizontal-border-color: rgb(231, 231, 231);

.sidebar {
  display: flex;
  flex-direction: column;
  width: 275px;
  background-color: #f5f5f5;
  border: 1px solid rgb(238, 238, 238);
}

.companyInfoWrapper {
  padding: 20px;
}

.companyInfo {
  color: rgb(20, 145, 0);
}

.searchSection {
  border-top: 1px solid $horizontal-border-color;
  border-bottom: 1px solid $horizontal-border-color;
  padding: 10px;

  .search {
    display: flex;
    border-radius: $border-radius;
    border: 2px solid rgb(20, 145, 0);
    background-color: rgba(20, 145, 0, 0.1);
    overflow: hidden;

    .iconContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 25px;
      width: 25px;
    }

    input.input {
      flex: 1;
      border: none;
      background-color: transparent;

      &:focus {
        outline: none;
      }
    }
  }
}

.items {
  flex: 1;
  overflow: auto;
  padding-bottom: 20px;
}

.sectionTitle {
  font-size: 14px;
  font-weight: 700;
  color: rgb(19, 96, 0);
  margin-left: 25px;
  margin-top: 25px;
  margin-bottom: 5px;
}

.pageButton {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 5px;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: $border-radius;

  &,
  &:active:not(.isActive) {
    color: rgb(54, 54, 54);
  }

  &:hover:not(.isActive) {
    background-color: rgba(20, 145, 0, 0.2);
  }
}

.signupLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px;
  color: rgba(0, 0, 0, 0.8);
  background-color: rgba(140, 140, 140, 0.1);
  font-size: 13px;
  border-radius: $border-radius;
  text-decoration: none;

  &:hover:not(.isActive) {
    background-color: rgba(140, 140, 140, 0.2);
  }
}

.isActive {
  background-color: $theme-color;
  color: white;
  cursor: default;
}

.footer {
  display: flex;
  flex-direction: column;
  box-shadow: 0px -10px 10px rgba(245, 245, 245);
}

.footerLinks {
  margin-left: 5px;
  margin-right: 5px;
  gap: 5px;
}

.accountControls {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  border-top: 1px solid $horizontal-border-color;
  padding: 20px;
}

.userInfo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.userName {
  color: rgb(54, 54, 54);
}

.userPicture {
  height: 35px;
  width: 35px;
  background-color: rgb(20, 145, 0);
  border-radius: 50%;
  display: inline-block;
}

.logout {
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: $border-radius;
  padding-left: 8px;
  padding-right: 8px;

  &:hover {
    background-color: rgba(100, 100, 100, 0.1);
  }
}

.hidden {
  visibility: hidden;
}
