/** @format */

.footerLink {
  display: flex;
  align-items: center;
  color: rgb(100, 100, 100);
  padding: 10px 20px;
  border-radius: 9px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: rgb(100, 100, 100, 0.1);
    color: rgb(65, 65, 65);
  }
}

.icon {
  width: 20px;
}

.leftContent {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 10px;
}
