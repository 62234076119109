/** @format */

@import '../../variables.scss';

.mobileNavLinks {
  background-color: $theme-color;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}

.items {
  margin-bottom: 10px;
}

.sectionTitle {
  font-size: 14px;
  font-weight: 700;
  color: rgb(224, 255, 227);
  margin-left: 25px;
  margin-top: 25px;
  margin-bottom: 5px;
}

.pageButton {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 5px;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: $border-radius;
  color: white;
}

.isActive {
  background-color: rgb(89, 181, 97);
}
