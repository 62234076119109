/** @format */

@import './variables.scss';

.loginOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(0.75rem);
  background-color: rgba(255, 255, 255, 0.5);

  padding-left: 10%;
  padding-right: 10%;
}

.content {
  display: flex;
  flex-direction: column;
  max-width: 350px;
}

.logoContainer {
  display: flex;
  justify-content: center;
}

.title {
  margin-bottom: 0px;
  font-weight: 700;
  text-align: center;
}

.blurb {
  font-size: 14px;
  color: rgb(111, 111, 111);
  text-align: center;
}

form {
  display: flex;
  flex: 1;
}

.form {
  flex: 1;
  display: flex;
  gap: 5px;
  margin-top: 15px;
}

input.input {
  flex: 1;
  padding: 5px;
  color: rgb(53, 53, 53);
  border: 1px solid rgb(237, 237, 237);
  border-radius: $border-radius;

  &::placeholder {
    color: rgb(200, 200, 200);
    opacity: 1; /* Firefox */
  }

  @media screen and (max-width: 1000px) {
    font-size: 16px;
  }

  &:focus {
    outline: none;
  }

  &.error {
    border: 1px solid #e5484d;
    border-radius: $border-radius;

    &:focus {
      outline: none !important;
      border: 1px solid red;
      border-radius: $border-radius;
      box-shadow: 0 0 10px #719ece;
    }
  }
}

.hidden {
  visibility: hidden;
}

.errorMessage {
  margin-top: 5px;
  color: rgb(111, 111, 111);
  font-size: 12px;
}
