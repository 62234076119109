/** @format */

@import './variables.scss';

.app {
  display: flex;
  height: 100vh;
  overflow: hidden;

  .sidebar {
    display: flex;
  }

  @media screen and (min-width: 1000px) {
    .mobileNavBar {
      display: none;
    }
  }

  @media screen and (max-width: 1000px) {
    height: stretch;

    flex-direction: column;

    .sidebar {
      display: none;
    }
  }
}

.content {
  flex: 1;
  display: flex;

  @media screen and (max-width: 1000px) {
    position: relative;

    .mobileNav {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
    }

    .mobileGetAccessLink {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: 20px;
    }
  }

  @media screen and (min-width: 1000px) {
    .mobileGetAccessLink {
      display: none;
    }
  }
}

// hide hubspot chat button on mobile
@media screen and (max-width: 1000px) {
  #hubspot-conversations-iframe {
    display: none;
  }
}
