/** @format */

@import '../../variables.scss';

.mobileGetAccessLink {
  background-color: $theme-color;
  color: white;
  padding: 8px 12px;
  border-radius: $border-radius;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  gap: 15px;
  text-decoration: none;
}

.text {
  white-space: nowrap;
}
