/** @format */

@import '../../variables.scss';

.mobileNavBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $theme-color;
  padding-left: 16px;
}

.companyInfo {
  color: white;
}

.toggleIsNavOpenButton {
  padding: 16px;
}

.icon {
  color: white;
}
