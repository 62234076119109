/** @format */

.companyInfo {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 700;
}

.text {
  font-size: 14px;
}
