/** @format */

.footerLink {
  display: flex;
  align-items: center;
  color: white;
  padding: 10px 20px;

  text-decoration: none;
}

.icon {
  width: 20px;
}

.leftContent {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 10px;
}
